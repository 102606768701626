@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Serif", sans-serif;
  background: white;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__2XJk3 {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__1mOnw {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__37eQC {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__3PA1P {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__3PA1P img {
  width: 100%;
}

.Login_LoginTitle__3bDPG {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__3bDPG span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__1weL1 {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__1xig4 {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__1xig4:focus {
  outline: none;
}
.Login_Phone__1xhkW {
  padding-left: 45px;
}
.Login_InputGroup__1weL1 i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__1oLil {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__jJAbx {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__8t1Mo {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__1xig4:focus ~ .Login_InputLabel__8t1Mo,
.Login_Input__1xig4:valid ~ .Login_InputLabel__8t1Mo {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__3AIvR {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__3AIvR:before,
.Login_Bar__3AIvR:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__3AIvR:before {
  left: 50%;
}
.Login_Bar__3AIvR:after {
  right: 50%;
}
/* active state */
.Login_Input__1xig4:focus ~ .Login_Bar__3AIvR:before,
.Login_Input__1xig4:focus ~ .Login_Bar__3AIvR:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__3IztT {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__1xig4:focus ~ .Login_Highlight__3IztT {
  animation: Login_inputHighlighter__1_Qoy 0.3s ease;
  -webkit-animation: Login_inputHighlighter__1_Qoy 0.3s ease;
  -moz-animation: Login_inputHighlighter__1_Qoy 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__1_Qoy {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__1_Qoy {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__3R3GB {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__1xig4:focus ~ .Login_Indicatif__3R3GB,
.Login_Input__1xig4:valid ~ .Login_Indicatif__3R3GB {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__3c6PU input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__3c6PU input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__3c6PU input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__3c6PU input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__3c6PU input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__3c6PU input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__3c6PU label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__3c6PU span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__24BD_ {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__1Viho {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__15Ipf {
  display: flex;
}
.RefInfo_RefInfoTitle__15Ipf h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__3RkZ_ img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__19zf5 {
  text-align: center;
}

.CGU_CGUWrapper__3Ld7Z {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__1A_Se {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__LIhpC {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__28Cbe {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__1EdKp {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__1EdKp p {
  margin-bottom: 0.3em;
}

.Modal_Modal__2iMh8 {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__2iMh8 {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__2vMgG {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__om_F9 {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__om_F9:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__om_F9:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__1E38t i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__1E38t span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__2dvQN i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__2S4WW i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__35FmG {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SectionTitle__4IXGb {
  text-align: center;
  font-family: "Merriweather", serif;
}
.Home_SectionTitle__4IXGb .Home_Emoji__3WS4Z {
  font-size: 2.8em;
}
.Home_SectionTitle__4IXGb h3 {
  font-size: 1.5em;
}
.Home_SectionSubtitle__l6dnp {
  font-size: 1rem;
  margin: 0.8em 2em;
  margin-left: 4em;
  color: #898786;
}

.Home_EmojiText__14InU {
  display: flex;
  margin: 0 2em;
  color: #5b5855;
}
.Home_EmojiText__14InU .Home_Emoji__3WS4Z {
  font-size: 1.2em;
}

.Home_EmojiText__14InU p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.Home_ParticiperButton__32yUM {
  text-align: center;
  margin: 1.5em 0;
}

.Home_HighlightNote__2fILc {
  margin: 2em 4em;
}
.Home_HighlightNote__2fILc span {
  display: inline-block;
  padding: 0.25em 2em;
  background-color: #e0004d;
  margin-bottom: 1em;
  border-radius: 3px;
}
.Home_HighlightNote__2fILc p {
  text-align: center;
  color: #e0004d;
  font-weight: bold;
  font-size: 1.1rem;
}

.Home_Illustration__1DKPl {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}
.Home_YoutubePlayer__1I4Qs {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

/* Caroussel */
@media screen and (max-width: 767px) {
  .Home_CarouselSection__1X9qA {
    margin: 30px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .Home_CarouselSection__1X9qA::-webkit-scrollbar {
    display: none; /* Safari & Chrome*/
  }

  .Home_CarouselItem__373y_ {
    flex: 0 0 auto;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 30px;
    width: 275px;
    height: 275px;
    box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .Home_CarouselItem__373y_:first-child {
    margin-left: 40px;
  }
  .Home_CourselItem__2SMyx:last-child {
    margin-right: 20px;
  }
}

.Home_GrandesEtapes__1E9nL {
  display: flex;
  justify-content: center;
}
.Home_GrandesEtapes__1E9nL img {
  width: 80%;
  max-width: 33em;
  margin-bottom: 2em;
}
.Home_HeroImage__3MJtZ {
  width: 100%;
  margin-top: 3.5em;
  margin-bottom: 1.5em;
  z-index: -1;
}
.Home_HeroImage__3MJtZ img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
  .Home_HeroImage__3MJtZ {
    margin-top: 0em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_HomeCardBackground__3RrnN {
    padding: 0 7em;
    background-color: #f3f2f0;
  }
  .Home_HomeCardContent__3NBqR {
    background-color: white;
    padding-bottom: 2em;
  }
  .Home_HeroImage__3MJtZ {
    width: 100%;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 3em;
  }
  /* .HeroImage img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  } */
  .Home_Section__1Aas8 {
    margin: 0 2em;
  }
  .Home_SectionTitle__4IXGb {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-align: left;
  }
  .Home_SectionTitle__4IXGb .Home_Emoji__3WS4Z {
    font-size: 3rem;
  }
  .Home_SectionSubtitle__l6dnp {
    font-size: 1.2rem;
    margin: 0.8em 0;
    margin-left: 3.5em;
  }
  .Home_EmojiText__14InU p {
    font-size: 1rem;
  }

  .Home_HighlightNote__2fILc p {
    font-size: 1.3rem;
  }
  .Home_ContactForm__3MUMr {
    margin: 0 auto;
    max-width: 40em;
  }

  /* Caroussel */
  .Home_CarouselSection__1X9qA {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2em 0;
  }

  .Home_CarouselItem__373y_ {
    margin: 0.5em 1em;
    width: 15em;
    height: 15em;
    box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_HomeCardBackground__3RrnN {
    padding: 0 13em;
  }

  .Home_HeroImage__3MJtZ {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 4em;
    margin-top: 3em;
  }
  .Home_Section__1Aas8 {
    margin: 0 5em;
  }
  .Home_Illustration__1DKPl {
    width: 25em;
    height: 25em;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
  .Home_HomeCardBackground__3RrnN {
    padding: 0 16em;
  }
}

.VisiteVirtuelle_YoutubePlayer__2Pqym {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.Blog_Blog__k540I {
  margin: 50px 0;
  box-sizing: border-box;
}

.Blog_BlogTitle__27hLh {
  display: flex;
  margin: 0 20px;
}
.Blog_BlogTitle__27hLh h2 {
  margin-left: 5px;
}

/* Small tablets */
@media (min-width: 620px) {
  .Blog_BlogTitle__27hLh {
    margin: 0 50px;
  }
}

@media screen and (min-width: 768px) {
  .Blog_Blog__k540I {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.BlogCard_BlogCard__12nVf {
  margin: 30px auto;
  max-width: 23rem;
  min-height: 32rem;
  width: 75%;
  background: #f3f2f0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 8px;
  overflow: hidden;
}

.BlogCard_BlogCardImage__2XEdA {
  height: 22em;
  width: 100%;
  z-index: -1;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  background-repeat: no-repeat;
  background-size: cover;
}

.BlogCard_BlogCardDate__2TzEA {
  text-align: end;
  margin: 0.7rem 1.5rem;
  font-size: 0.9em;
  color: #9dabb6;
}

.BlogCard_BlogCardContent__3OuHO {
  margin: 0.7rem 1.5rem;
}
.BlogCard_BlogCardContent__3OuHO p {
  margin: 0.3rem 0;
}

.BlogCard_BlogCardButton__3JV-j {
  text-align: end;
  margin: 0.7rem 1.5rem;
  margin-top: 2rem;
}

/* Small tablets */
@media (min-width: 620px) {
  .BlogCard_BlogCard__12nVf {
    margin: 50px auto;
  }
}

@media screen and (min-width: 768px) {
  .BlogCard_BlogCard__12nVf {
    margin: 20px auto;
  }
}

.LongButton_LongButton__1VZQG {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__1VZQG:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.ContactForm_ContactForm__1-KM4 {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__33VCq {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__3nKBx {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__32wTS {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__IiLIv {
  text-align: end;
}
.ContactForm_Success__2tb1f {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__1SZlL {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Footer_Footer__17aDh {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e0004d;
}
.Footer_LogoContainer__3jUTJ {
  width: 10em;
  margin: 1em 0;
}
.Footer_LogoContainer__3jUTJ img {
  width: 100%;
}
.Footer_LegalLinks__2Hkv8 {
  display: flex;
  margin-top: 1em;
}
.Footer_LegalLinks__2Hkv8 p {
  cursor: pointer;
  margin: 0 1em;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
}
.Footer_Copyrights__2gGwx {
  margin: 1em 0;
  color: white;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__2gGwx span {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
}

.PDC_CGUTitle__3TdIW {
  text-align: center;
}

.PDC_CGUWrapper__RMmAC {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__39IoE {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__3XelQ {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__2LXhi {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__1IGp0 {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__1IGp0 p {
  margin-bottom: 0.3em;
}

.Separator_Separator__PyIun {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__3e9dk {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__3mDUw {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__1u0UY h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__3mcrK {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__3mDUw {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__1u0UY h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__3mDUw {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__1u0UY h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__3mDUw {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__1vFFG {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__1vFFG {
    display: none;
  }
}

.SideDrawer_Open__3zluZ {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__3YUvM {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__2zCNL {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__1SQmq {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__1SQmq {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__3URVF {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__1SQmq {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__2r-xs {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__2r-xs a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__2r-xs a:hover,
.NavigationItem_NavigationItem__2r-xs a:active,
.NavigationItem_NavigationItem__2r-xs a.NavigationItem_active__HJpuN {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__2r-xs {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__2r-xs a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__2r-xs a:hover,
  .NavigationItem_NavigationItem__2r-xs a:active,
  .NavigationItem_NavigationItem__2r-xs a.NavigationItem_active__HJpuN {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.ProfileSetup_Wrapper__TSAdD {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__2Tznq {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__2P801 {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__3RMFA {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__3RMFA img {
  width: 100%;
}

.ProfileSetup_Title__171zE {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__171zE span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__2XL_n {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__3-kGl {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__3uS2C {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__2NI5U {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__i5_tJ {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__w8lCo {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: rgba(0, 78, 124, 0.178);
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__w8lCo:hover {
  background-color: #003c71;
  color: white;
}

.NextButton_NextButton__w8lCo:active {
  background-color: #003c71;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__w8lCo i {
  padding-left: 2px;
}

.BackButton_BackButton__2xB3Z {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: rgba(0, 78, 124, 0.178);
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__2xB3Z:hover {
  background-color: #003c71;
  color: white;
}

.BackButton_BackButton__2xB3Z:active {
  background-color: #003c71;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__2xB3Z i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__2wlhr {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #e0004d;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__2wlhr::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__l5Iig {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

/* SART ------------Basic Input--------------- */
.Input_InputGroup__3LgJW {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Input_Input__2BWt- {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input_Input__2BWt-:focus {
  outline: none;
}
.Input_Phone__2vtVK {
  padding-left: 35px;
}
.Input_InputGroup__3LgJW i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.Input_InputLabel__3yXd0 {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input_Input__2BWt-:focus ~ .Input_InputLabel__3yXd0,
.Input_Input__2BWt-:valid ~ .Input_InputLabel__3yXd0 {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Input_Bar__1p6uo {
  position: relative;
  display: block;
  width: 100%;
}
.Input_Bar__1p6uo:before,
.Input_Bar__1p6uo:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Input_Bar__1p6uo:before {
  left: 50%;
}
.Input_Bar__1p6uo:after {
  right: 50%;
}
/* active state */
.Input_Input__2BWt-:focus ~ .Input_Bar__1p6uo:before,
.Input_Input__2BWt-:focus ~ .Input_Bar__1p6uo:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Input_Highlight__3K_gs {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input_Input__2BWt-:focus ~ .Input_Highlight__3K_gs {
  animation: Input_inputHighlighter__C6HWi 0.3s ease;
  -webkit-animation: Input_inputHighlighter__C6HWi 0.3s ease;
  -moz-animation: Input_inputHighlighter__C6HWi 0.3s ease;
}
/* Animations */
@keyframes Input_inputHighlighter__C6HWi {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Input_inputHighlighter__C6HWi {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Input_Indicatif__2SzfP {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input_Input__2BWt-:focus ~ .Input_Indicatif__2SzfP,
.Input_Input__2BWt-:valid ~ .Input_Indicatif__2SzfP {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.Input_RadioFormElement__2umbn {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Input_RadioFormElement__2umbn::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Input_RadioDivElement__ZSsrB {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.Input_RadioDivElement__ZSsrB:first-child {
  margin-left: 10vw;
}

.Input_RadioDivElement__ZSsrB input {
  opacity: 0;
}

.Input_RadioDivElement__ZSsrB label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.Input_RadioDivElement__ZSsrB span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_RadioDivElement__ZSsrB input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e6404d;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */

.QuestionTwo_QuestionTwo__NpBcb {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__NpBcb p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__D4LiZ {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__3mhj0 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__3WsJo {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__3WsJo input[type="radio"] {
  display: none;
}

.Radio_FormElement__3WsJo label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: rgba(0, 78, 124, 0.178);
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__3WsJo input[type="radio"]:checked + label {
  background-color: #003c71;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */

.Travaux_Main__clhiV {
  padding: 0 1em;
}
.Travaux_CardListTitle__1whKt {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Travaux_CardListTitle__1whKt span {
  font-size: 1.5rem;
  font-weight: bold;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_Travaux__a_RHl {
    margin: 0 2em;
    margin-top: 1em;
  }
  .Travaux_MainCards__h96zT {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .Travaux_NoCard__2CuV6 {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_Travaux__a_RHl {
    margin: 0 10em;
  }
}

.IntroModal_IntroModal__2sMAa {
  height: 50vh;
  overflow-y: scroll;
  margin-bottom: 3em;
}

.IntroModal_IntroTitle__3mPEU {
  /* margin-right: 1em; */
}

.IntroModal_IntroDescription__3rbPW {
  margin-right: 1em;
}

.IntroModal_IntroButton__OpjR8 {
  margin-top: 1.5em;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  position: fixed;
  bottom: 0.2em;
  width: 90%;
}

.IntroModal_Personnalisable__2dWyT {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #bbd5ff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #bbd5ff 40%);
}

.IntroModal_Informative__flJDJ {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #fcd79d));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fcd79d 40%);
}

.Toolbar_Toolbar__3g4pm {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 3em;
}

.Toolbar_Logo__o49S5 h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
  cursor: pointer;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Toolbar__3g4pm {
    padding: 0 1em;
  }
  .Toolbar_Logo__o49S5 h3 {
    font-size: 1.8rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__3g4pm {
    padding: 2em 1em;
  }

  .Toolbar_Logo__o49S5 h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__2tA44 {
  margin-bottom: 0.5em;
}
.Filter_Filter__2tA44 p {
  font-size: 0.9rem;
}
.Filter_Filter__2tA44 span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__1Aujt form {
  display: flex;
  margin: 0.4rem 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Filter_Categories__1Aujt form::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Filter_Personnalisable__R2iqU {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__R2iqU label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__R2iqU input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__R2iqU input[type="checkbox"]:checked + label {
  background: #e0ecff;
  color: #263238;
}

/* =========================== */
.Filter_Informative__2Csn1 {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__2Csn1 label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__2Csn1 input[type="checkbox"] {
  display: none;
}

.Filter_Informative__2Csn1 input[type="checkbox"]:checked + label {
  background: #fff3e0;
  color: #263238;
}

.CardList_CardList__INKNj {
  margin-bottom: 2em;
}

.CardList_List__3UA5t {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardList_List__3UA5t {
    align-items: flex-start;
    width: 21em;
  }
}

.Card_Card__3UW7B {
  /* background-color: #e0ecff; */
  display: flex;
  border-radius: 14px;
  position: relative;
  padding: 0.3em 0;
  max-width: 22em;
  margin-bottom: 1em;
  cursor: pointer;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
}
.Card_SelectedCard__14DA9 {
  border: 2px solid #e57373;
}

.Card_Thumbnail__2fp8J {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.Card_Thumbnail__2fp8J img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__2NkC3 {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__t4r2G {
  display: flex;
  flex-direction: column;
}

.Card_Description__1vmfU {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__1FWqn {
  position: absolute;
  bottom: 0.3em;
  right: 1em;
}

.Card_Tags__nxUvd {
  font-size: 0.55rem;
  color: white;
}
.Card_Tags__nxUvd span {
  background-color: #757575;
  padding: 0.2em 0.4em;
  border-radius: 24px;
  text-transform: capitalize;
}
.Card_Tags__nxUvd :last-child {
  margin-left: 0.4em;
}

.CardModal_CardModal__4GAen {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__4GAen {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__4GAen {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.concertableLayoutOne_CardHeader__2m_N6 {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__1qtWo h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__1qtWo h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.concertableLayoutOne_Score__2CYOU {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.concertableLayoutOne_Score__2CYOU p {
  font-size: 0.9rem;
  color: #e0004d;
}
.concertableLayoutOne_Score__2CYOU span {
  font-weight: bold;
}

.concertableLayoutOne_ImagePreview__cKtI6 {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.concertableLayoutOne_ImagePreview__cKtI6 img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.concertableLayoutOne_CardContent__1Bkiw {
  margin: 0 1.5em;
}

.concertableLayoutOne_Emoji__2cRcb {
  font-size: 1.8rem;
}
.concertableLayoutOne_DescriptionTitle__3Cp7z p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.concertableLayoutOne_DescriptionContent__2TPKf {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.concertableLayoutOne_QuestionsHeader__2AkwK {
  color: #e0004d;
  margin-top: 0.7em;
}

.concertableLayoutOne_QuestionTitle__1nYHy {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.concertableLayoutOne_QuestionTitle__1nYHy p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__Ljtc3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__1u5bg {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__1u5bg label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__1u5bg p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__1u5bg img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}

.concertableLayoutOne_RadioElement__1u5bg input {
  display: none;
}

.concertableLayoutOne_RadioElement__1u5bg input[type="radio"]:checked + label img {
  border: 7px solid #e0004d;
}
.concertableLayoutOne_RadioElement__1u5bg input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.concertableLayoutOne_Button__1W1EP {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.concertableLayoutOne_CommentSection__s2lyC {
  margin-top: 1em;
  text-align: center;
}

.concertableLayoutOne_MUIForm__2RPfW {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__2HZZJ {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__3A4qB {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__2VlPR {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .concertableLayoutOne_Card__McAq1 {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .concertableLayoutOne_LeftSection__3eDYD {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__20xiC {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .concertableLayoutOne_RightFooter__30T6n {
    margin-top: 2em;
  }
}

.NonConcertableLayoutOne_CardHeader__3hM88 {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__2KwUG h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__2KwUG h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.NonConcertableLayoutOne_Score__2M90x {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.NonConcertableLayoutOne_Score__2M90x p {
  font-size: 0.9rem;
  color: #e0004d;
}
.NonConcertableLayoutOne_Score__2M90x span {
  font-weight: bold;
}

.NonConcertableLayoutOne_YoutubePlayer__3eiv8 {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_CardContent__2o3hm {
  margin: 0 1.5em;
}

.NonConcertableLayoutOne_Emoji__jK8bt {
  font-size: 1.8rem;
}
.NonConcertableLayoutOne_DescriptionTitle__3M2aD p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.NonConcertableLayoutOne_DescriptionContent__2Am6C {
  margin-top: 0.5em;
  color: #2d2926;
  font-size: 0.85rem;
}

.NonConcertableLayoutOne_NCSection__1zbP5 {
  margin-top: 1.1em;
}

.NonConcertableLayoutOne_NCItem__21FeV {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.NonConcertableLayoutOne_NCItem__21FeV .NonConcertableLayoutOne_Emoji__jK8bt {
  font-size: 1.7em;
}
.NonConcertableLayoutOne_NCItem__21FeV p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.NonConcertableLayoutOne_MapImage__ZVw-x {
  margin-top: 0.85em;
}
.NonConcertableLayoutOne_MapImage__ZVw-x img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.NonConcertableLayoutOne_Button__1brda {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.NonConcertableLayoutOne_CommentSection__1117b {
  margin-top: 1em;
  text-align: center;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .NonConcertableLayoutOne_Card__38XNy {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .NonConcertableLayoutOne_LeftSection__V90dX {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__6N1_S {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .NonConcertableLayoutOne_MUIForm__26w2L {
    margin-bottom: 8em;
  }
  .NonConcertableLayoutOne_RightFooter__fkuY5 {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}

.ProfilePage_HeaderImageWrapper__Xajo6 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__Xajo6 img {
  width: 80%;
}

.ProfilePage_ProfilePage__11Q1N h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__11Q1N h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__2JQvv {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__1U8H1 {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__1U8H1:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__3Xj5s {
  padding: 0 1em;
}
.Resultats_CardListTitle__mR5gn {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_CardListTitle__mR5gn span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Resultats_IllustrationsSection__1YudM {
  padding: 0 1em;
  margin-top: 0.5em;
}
.Resultats_StatsIllustration__2W-2C {
  margin-top: 2em;
}
.Resultats_StatsContent__13T6o {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__13T6o h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__13T6o p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__1QF3S {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Travaux__H_DtR {
    margin: 0 2em;
    margin-top: 1em;
  }
  .Resultats_MainCards__1oSmm {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .Resultats_NoCard__3rDKl {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Travaux__H_DtR {
    margin: 0 10em;
  }
  /* .IllustrationsContent {
    display: flex;
  } */
}


.ResultsConcertableLayout_CardHeader__36qUs {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ResultsConcertableLayout_CardTitle__3kWoI h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.ResultsConcertableLayout_CardTitle__3kWoI h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.ResultsConcertableLayout_Score__217cC {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.ResultsConcertableLayout_Score__217cC p {
  font-size: 0.9rem;
  color: #e0004d;
}
.ResultsConcertableLayout_Score__217cC span {
  font-weight: bold;
}

.ResultsConcertableLayout_ImagePreview__24o7j {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.ResultsConcertableLayout_ImagePreview__24o7j img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.ResultsConcertableLayout_CardContent__CSHQ9 {
  margin: 0 1.5em;
}

.ResultsConcertableLayout_Emoji__ibWT2 {
  font-size: 1.8rem;
}
.ResultsConcertableLayout_DescriptionTitle__1k5Vl p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.ResultsConcertableLayout_DescriptionContent__3KKK- {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.ResultsConcertableLayout_QuestionsHeader__2ykzz {
  color: #e0004d;
  margin-top: 0.7em;
}

.ResultsConcertableLayout_QuestionTitle__2idSW {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.ResultsConcertableLayout_QuestionTitle__2idSW p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ResultsConcertableLayout_QuestionEnsemble__3RHHE {
  display: flex;
  margin: 1em 0;
}
.ResultsConcertableLayout_QuestionPercentage__2Og76 {
  /* margin-right: 1em; */
  width: 6em;
}
.ResultsConcertableLayout_Winner__2GKcT {
  background-color: #e0004e71;
  padding: 0.3em;
  border-radius: 10px;
  color: #f3f2f0;
  /* font-weight: bold; */
}

/* ============================== radio form */
.ResultsConcertableLayout_RadioForm__2RMHi {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.ResultsConcertableLayout_RadioElement__xLuz4 {
  margin: 1em 0.8em;
}

.ResultsConcertableLayout_RadioElement__xLuz4 label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ResultsConcertableLayout_RadioElement__xLuz4 p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.ResultsConcertableLayout_RadioElement__xLuz4 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}

.ResultsConcertableLayout_RadioElement__xLuz4 input {
  display: none;
}

.ResultsConcertableLayout_RadioElement__xLuz4 input[type="radio"]:checked + label img {
  border: 7px solid #e0004d;
}
.ResultsConcertableLayout_RadioElement__xLuz4 input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.ResultsConcertableLayout_Button__3ntvm {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.ResultsConcertableLayout_CommentSection__nOszK {
  margin-top: 1em;
}
.ResultsConcertableLayout_CommentGroup__Dei-9 {
  margin-top: 1em;
  background-color: #e0ecff83;
  padding: 1em;
  padding-top: 0.5em;
  border-radius: 10px;
}
.ResultsConcertableLayout_Comment__1yWe4 {
  display: flex;
  margin-top: 0.8em;
}
.ResultsConcertableLayout_Comment__1yWe4 p {
  margin-left: 1em;
}
.ResultsConcertableLayout_CommentAnswer__tPby_ {
  margin-top: 0.5em;
  background: rgba(255, 255, 255, 0.397);
  padding: 1em;
  border-radius: 10px;
}

.ResultsConcertableLayout_MUIForm__1pG4u {
  margin-left: 2em;
}

.ResultsConcertableLayout_NCSection__33czn {
  margin-top: 1.1em;
}
.ResultsConcertableLayout_MapImage__lx3cQ {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.ResultsConcertableLayout_DropdownIlots__2SB6- {
  margin: 1em 0;
}

.ResultsConcertableLayout_Notices__1veZQ {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .ResultsConcertableLayout_Card__25xzu {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .ResultsConcertableLayout_LeftSection__3WNM- {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .ResultsConcertableLayout_RightSection__387x3 {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .ResultsConcertableLayout_RightFooter__2IP-v {
    margin-top: 2em;
  }
}

.ResultsNonConcertableLayout_CardHeader__mgPN5 {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ResultsNonConcertableLayout_CardTitle__1Y5kB h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.ResultsNonConcertableLayout_CardTitle__1Y5kB h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.ResultsNonConcertableLayout_Score__bkwRt {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.ResultsNonConcertableLayout_Score__bkwRt p {
  font-size: 0.9rem;
  color: #e0004d;
}
.ResultsNonConcertableLayout_Score__bkwRt span {
  font-weight: bold;
}

.ResultsNonConcertableLayout_YoutubePlayer__oAImw {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.ResultsNonConcertableLayout_CardContent__3P6P6 {
  margin: 0 1.5em;
}

.ResultsNonConcertableLayout_Emoji__1Sa25 {
  font-size: 1.8rem;
}
.ResultsNonConcertableLayout_DescriptionTitle__3xw0E p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.ResultsNonConcertableLayout_DescriptionContent__3kaOD {
  margin-top: 0.5em;
  color: #2d2926;
  font-size: 0.85rem;
}

.ResultsNonConcertableLayout_NCSection__KQ1QK {
  margin-top: 1.1em;
}

.ResultsNonConcertableLayout_NCItem__1_9Kg {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.ResultsNonConcertableLayout_NCItem__1_9Kg .ResultsNonConcertableLayout_Emoji__1Sa25 {
  font-size: 1.7em;
}
.ResultsNonConcertableLayout_NCItem__1_9Kg p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.ResultsNonConcertableLayout_MapImage__3WbB7 {
  margin-top: 0.85em;
}
.ResultsNonConcertableLayout_MapImage__3WbB7 img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.ResultsNonConcertableLayout_Button__2Syry {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.ResultsNonConcertableLayout_CommentSection__I8eK4 {
  margin-top: 1em;
}
.ResultsNonConcertableLayout_Comment__3tHoq {
  display: flex;
  margin-top: 0.8em;
}
.ResultsNonConcertableLayout_Comment__3tHoq p {
  margin-left: 1em;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .ResultsNonConcertableLayout_Card__1U13- {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .ResultsNonConcertableLayout_LeftSection__3D4F4 {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .ResultsNonConcertableLayout_RightSection__34_7- {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .ResultsNonConcertableLayout_MUIForm__2IbNy {
    margin-bottom: 8em;
  }
  .ResultsNonConcertableLayout_RightFooter__yNRg- {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}

.PageOne_SectionTitle__11uF0 {
  text-align: center;
  font-family: "Merriweather", serif;
}
.PageOne_SectionTitle__11uF0 .PageOne_Emoji__jPX9n {
  font-size: 2.8em;
}
.PageOne_SectionTitle__11uF0 h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__3pLTL {
  font-size: 1rem;
  margin: 0.8em 2em;
  margin-left: 4em;
  color: #898786;
}

.PageOne_EmojiText__1f3Z4 {
  display: flex;
  margin: 0 2em;
  color: #5b5855;
}
.PageOne_EmojiText__1f3Z4 .PageOne_Emoji__jPX9n {
  font-size: 1.2em;
}

.PageOne_EmojiText__1f3Z4 p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__3ID9r {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__3ZRpx {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__2GFX2 {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__2GFX2::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__2TU0N {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__2TU0N:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__305rV:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__w2o1K {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__w2o1K img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__k_Wet {
  margin-top: 3em;
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__k_Wet img {
  width: 100%;
}

.PageOne_HighlightNote__3zBgp {
  margin: 2em 4em;
}
.PageOne_HighlightNote__3zBgp span {
  display: inline-block;
  padding: 0.25em 2em;
  background-color: #e0004d;
  margin-bottom: 1em;
  border-radius: 3px;
}
.PageOne_HighlightNote__3zBgp p {
  text-align: center;
  color: #e0004d;
  font-weight: bold;
  font-size: 1.1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__k_Wet {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
    margin-top: 8em;
  }
  .PageOne_HeroImage__k_Wet img {
    border-radius: 15px;
  }
  .PageOne_SectionTitle__11uF0 {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-align: left;
  }
  .PageOne_SectionTitle__11uF0 .PageOne_Emoji__jPX9n {
    font-size: 3rem;
  }
  .PageOne_SectionSubtitle__3pLTL {
    font-size: 1.2rem;
    margin: 0.8em 0;
    margin-left: 3.5em;
  }
  .PageOne_EmojiText__1f3Z4 p {
    font-size: 1rem;
  }
  .PageOne_ContactForm__2uiQP {
    margin: 0 auto;
    max-width: 40em;
  }
  .PageOne_HighlightNote__3zBgp p {
    font-size: 1.3rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__k_Wet {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
    margin-top: 8em;
  }
  .PageOne_Section__1zNX7 {
    margin: 0 20em;
  }
  .PageOne_Illustration__3ZRpx {
    width: 25em;
    height: 25em;
  }
}

